import React from 'react';

import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
  Typography,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import dotenv from 'dotenv';
dotenv.config();

const useStyles = makeStyles((theme) => ({
  signinContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center', // Vertically center the container
    minHeight: '100vh', // Set container height to full viewport height
    padding: theme.spacing(4), // Add padding for better spacing
    backgroundColor: '#f0f0f0', // Add background color for better visibility
  },
  inputField: {
    marginBottom: theme.spacing(2), // Add margin bottom for input fields
    width: '100%',
  },
  signinButton: {
    width: '100%',
    marginTop: theme.spacing(2), // Add margin top for the sign-in button
  },
  errorMessage: {
    color: 'red',
    marginTop: theme.spacing(1), // Add margin top for error message
    textAlign: 'center',
  },
}));
const SignIn = ({ onSignIn }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [signInError, setSignInError] = useState(false);

  const handleSignIn = () => {
    // Basic email validation
    if (!email || !email.includes('@')) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    // Basic password validation
    if (!password || password.length < 6) {
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    // Mock authentication - Replace with actual authentication logic
    console.log(process.env.EMAIL);
    if (email === 'fivepillarsbaf@gmail.com' && password === 'Junaidjk@21') {
      setSignInError(false);
      localStorage.setItem(
        'x-token-access',
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      );
      onSignIn(true);
    } else {
      setSignInError(true);
      localStorage.clear();
      onSignIn(false);
    }
  };
  const paperStyle = {
    padding: 20,
    height: '70vh',
    width: 280,
    margin: '20px auto',
  };
  const avatarStyle = { backgroundColor: '#1bbd7e' };
  const btnstyle = { margin: '8px 0' };
  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
          <h2>Sign In</h2>
        </Grid>
        <TextField
          className={classes.inputField}
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={emailError}
          helperText={emailError ? 'Please enter a valid email' : ''}
        />
        <TextField
          className={classes.inputField}
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={passwordError}
          helperText={
            passwordError ? 'Password must be at least 6 characters' : ''
          }
        />
        {signInError && (
          <p className={classes.errorMessage}>
            Incorrect email or password. Please try again.
          </p>
        )}
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          onClick={handleSignIn}
          fullWidth
        >
          Sign in
        </Button>
      </Paper>
    </Grid>
  );
};

export default SignIn;
