import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { InvoiceItem, InvoiceModal } from '../components/index';
import InputGroup from 'react-bootstrap/InputGroup';

class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    const { invoiceData: data } = this.props;
    if (data) {
      const { info, items, total, subTotal, currency } = data;
      this.state = {
        id: info.id,
        currency: currency,
        currentDate: info.currentDate,
        invoiceNumber: info.invoiceNumber,
        dateOfIssue: info.dateOfIssue,
        billTo: info.billTo,
        billToEmail: info.billToEmail,
        billToAddress: info.billToAddress,
        billFrom: info.billFrom,
        billFromEmail: info.billFromEmail,
        billFromAddress: info.billFromAddress,
        notes: info.notes,
        total: total,
        subTotal: subTotal,
        taxRate: info.taxRate,
        taxAmmount: info.taxAmmount,
        discountRate: info.discountRate,
        discountAmmount: info.discountAmmount,
        items: items,
      };
      this.editField = this.editField.bind(this);
      this.handleAddEvent = this.handleAddEvent.bind(this);
      return;
    }
    this.state = {
      id: (+new Date() + Math.floor(Math.random() * 999999)).toString(36),
      isOpen: false,
      currency: '$',
      currentDate: '',
      invoiceNumber: '',
      dateOfIssue: '',
      billTo: '',
      billToEmail: '',
      billToAddress: '',
      billFrom: '',
      billFromEmail: '',
      billFromAddress: '',
      gstNo: '',
      sebi: '',
      notes: '',
      total: 0.0,
      subTotal: 0.0,
      taxRate: 0.0,
      taxAmmount: 0.0,
      discountRate: 0.0,
      discountAmmount: 0.0,
      executive: '',
      empCode: '',
      investment: '',
      items: [
        {
          id: (+new Date() + Math.floor(Math.random() * 999999)).toString(36),
          name: '',
          description: '',
          price: 0.0,
          quantity: 0,
        },
      ],
    };
    this.editField = this.editField.bind(this);
    this.handleAddEvent = this.handleAddEvent.bind(this);
  }

  componentDidMount(prevProps) {
    this.handleCalculateTotal();
  }

  handleAddEvent(evt) {
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: '',
      price: 1.0,
      description: '',
      quantity: 1,
    };
    this.state.items.push(items);
    this.setState(this.state.items, () => {
      this.handleCalculateTotal();
    });
  }
  handleRowDel(itemToDelete) {
    const filteredItems = this.state.items.filter(
      (item) => item !== itemToDelete
    );
    this.setState({ items: filteredItems }, () => {
      this.handleCalculateTotal();
    });
  }
  handleCalculateTotal() {
    var items = this.state.items;
    var subTotal = items
      .reduce((acc, item) => {
        return acc + parseFloat(item.price) * parseInt(item.quantity);
      }, 0)
      .toFixed(2);

    this.setState(
      {
        subTotal: parseFloat(subTotal).toFixed(2),
      },
      () => {
        this.setState(
          {
            taxAmmount: parseFloat(
              parseFloat(subTotal) * (this.state.taxRate / 100)
            ).toFixed(2),
          },
          () => {
            this.setState(
              {
                discountAmmount: parseFloat(
                  parseFloat(subTotal) * (this.state.discountRate / 100)
                ).toFixed(2),
              },
              () => {
                this.setState({
                  total:
                    subTotal -
                    this.state.discountAmmount +
                    parseFloat(this.state.taxAmmount),
                });
              }
            );
          }
        );
      }
    );
  }
  onItemizedItemEdit(evt) {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var items = this.state.items.slice();
    var newItems = items.map(function (items) {
      for (var key in items) {
        if (key == item.name && items.id == item.id) {
          items[key] = item.value;
        }
      }
      return items;
    });
    this.setState({ items: newItems });
    this.handleCalculateTotal();
  }
  editField = (event) => {
    this.setState(() => ({
      [event.target.name]: event.target.value,
    }));
    this.handleCalculateTotal();
  };
  onCurrencyChange = (selectedOption) => {
    this.setState(selectedOption);
  };
  openModal = (event) => {
    event.preventDefault();
    this.handleCalculateTotal();
    this.setState({ isOpen: true });
  };
  closeModal = (event) => this.setState({ isOpen: false });
  render() {
    return (
      <Form onSubmit={this.openModal} className="justify-content-center">
        <Row>
          <Col md={8} lg={9}>
            <Card className="p-4 p-xl-5 my-3 my-xl-4">
              <div className="d-flex flex-row align-items-start justify-content-between mb-3">
                <div class="d-flex flex-column">
                  <div className="d-flex flex-column">
                    <div class="mb-2">
                      <span className="fw-bold">Current&nbsp;Date:&nbsp;</span>
                      <span className="current-date">
                        {new Date().toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <span className="fw-bold d-block me-2">Due&nbsp;Date:</span>
                    <Form.Control
                      type="date"
                      value={this.state.dateOfIssue}
                      name={'dateOfIssue'}
                      onChange={(event) => this.editField(event)}
                      style={{
                        maxWidth: '150px',
                      }}
                      required="required"
                    />
                  </div>

                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ marginTop: '10px' }}
                  >
                    <span className="fw-bold d-block me-2">
                      Executive&nbsp;Name:
                    </span>
                    <Form.Control
                      type="text"
                      value={this.state.executive}
                      name={'executive'}
                      onChange={(event) => this.editField(event)}
                      style={{
                        maxWidth: '150px',
                      }}
                      required="required"
                    />
                  </div>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ marginTop: '10px' }}
                  >
                    <span className="fw-bold d-block me-2">
                      Emp&nbsp;Code:&nbsp;
                    </span>
                    <Form.Control
                      type="text"
                      value={this.state.empCode}
                      name={'empCode'}
                      onChange={(event) => this.editField(event)}
                      style={{
                        maxWidth: '150px',
                      }}
                      required="required"
                    />
                  </div>
                </div>

                <div className="d-flex flex-row align-items-right">
               
                  <span className="fw-bold me-2">
                    Invoice&nbsp;Number:&nbsp;
                  </span>
                  <Form.Control
                    type="number"
                    value={this.state.invoiceNumber}
                    name={'invoiceNumber'}
                    onChange={(event) => this.editField(event)}
                    min="1"
                    style={{
                      maxWidth: '70px',
                    }}
                    required="required"
                  />
                </div>
              </div>
              <div className="d-flex flex-row align-items-right">
                <span className="fw-bold me-2">
                  Investment&nbsp;Capital:&nbsp;
                </span>
                <Form.Control
                  type="number"
                  value={this.state.investment}
                  name={'investment'}
                  onChange={(event) => this.editField(event)}
                  min="1"
                  style={{
                    maxWidth: '70px',
                  }}
                />
                
              </div>
              <hr className="my-4" />
              <Row className="mb-5">
                <Col>
                  <Form.Label className="fw-bold">Bill to:</Form.Label>
                  <Form.Control
                    placeholder={'Who is this invoice to?'}
                    rows={3}
                    value={this.state.billTo}
                    type="text"
                    name="billTo"
                    className="my-2"
                    onChange={(event) => this.editField(event)}
                    autoComplete="name"
                    required="required"
                  />
                  <Form.Control
                    placeholder={'Email address'}
                    value={this.state.billToEmail}
                    type="email"
                    name="billToEmail"
                    className="my-2"
                    onChange={(event) => this.editField(event)}
                    autoComplete="email"
                    required="required"
                  />
                  <Form.Control
                    placeholder={'Billing address'}
                    value={this.state.billToAddress}
                    type="text"
                    name="billToAddress"
                    className="my-2"
                    autoComplete="address"
                    onChange={(event) => this.editField(event)}
                    required="required"
                  />
                </Col>
                <Col>
                  <Form.Label className="fw-bold">Bill from:</Form.Label>
                  <Form.Control
                    placeholder={'Who is this invoice from?'}
                    rows={3}
                    value={this.state.billFrom}
                    type="text"
                    name="billFrom"
                    className="my-2"
                    onChange={(event) => this.editField(event)}
                    autoComplete="name"
                    required="required"
                  />
                  <Form.Control
                    placeholder={'Email address'}
                    value={this.state.billFromEmail}
                    type="email"
                    name="billFromEmail"
                    className="my-2"
                    onChange={(event) => this.editField(event)}
                    autoComplete="email"
                    required="required"
                  />
                  <Form.Control
                    placeholder={'Billing address'}
                    value={this.state.billFromAddress}
                    type="text"
                    name="billFromAddress"
                    className="my-2"
                    autoComplete="address"
                    onChange={(event) => this.editField(event)}
                    required="required"
                  />
                </Col>
              </Row>
              <InvoiceItem
                onItemizedItemEdit={this.onItemizedItemEdit.bind(this)}
                onRowAdd={this.handleAddEvent.bind(this)}
                onRowDel={this.handleRowDel.bind(this)}
                currency={this.state.currency}
                items={this.state.items}
              />
              <Row className="mt-4 justify-content-end">
                <Col lg={6}>
                  <div className="d-flex flex-row align-items-start justify-content-between">
                    <span className="fw-bold">Subtotal:</span>
                    <span>
                      {this.state.currency}
                      {this.state.subTotal}
                    </span>
                  </div>
                  <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                    <span className="fw-bold">Discount:</span>
                    <span>
                      <span className="small ">
                        ({this.state.discountRate || 0}%)
                      </span>
                      {this.state.currency}
                      {this.state.discountAmmount || 0}
                    </span>
                  </div>
                  <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                    <span className="fw-bold">Tax:</span>
                    <span>
                      <span className="small ">
                        ({this.state.taxRate || 0}%)
                      </span>
                      {this.state.currency}
                      {this.state.taxAmmount || 0}
                    </span>
                  </div>
                  <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                    <span className="fw-bold">GST Number:</span>
                    <span>{this.state.gstNo || '######'}</span>
                  </div>
                  <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                    <span className="fw-bold">SEBI Number:</span>
                    <span>{this.state.sebi || '######'}</span>
                  </div>
                  <hr />
                  <div
                    className="d-flex flex-row align-items-start justify-content-between"
                    style={{
                      fontSize: '1.125rem',
                    }}
                  >
                    <span className="fw-bold">Total:</span>
                    <span className="fw-bold">
                      {this.state.currency}
                      {this.state.total.toFixed(2) || 0}
                    </span>
                  </div>
                </Col>
              </Row>
              <hr className="my-4" />
              <Form.Label className="fw-bold">Notes:</Form.Label>
              <Form.Control
                placeholder="Thanks for your business!"
                name="notes"
                value={this.state.notes}
                onChange={(event) => this.editField(event)}
                as="textarea"
                className="my-2"
                rows={1}
              />
            </Card>
          </Col>
          <Col md={4} lg={3}>
            <div className="sticky-top pt-md-3 pt-xl-4">
              <Button variant="primary" type="submit" className="d-block w-100">
                Review Invoice
              </Button>
              <InvoiceModal
                type={this.props.invoiceData ? 1 : 2}
                showModal={this.state.isOpen}
                closeModal={this.closeModal}
                info={this.state}
                items={this.state.items}
                currency={this.state.currency}
                subTotal={this.state.subTotal}
                taxAmmount={this.state.taxAmmount}
                discountAmmount={this.state.discountAmmount}
                total={this.state.total}
                gstNo={this.state.gstNo}
                sebi={this.state.sebi}
              />
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Currency:</Form.Label>
                <Form.Select
                  onChange={(event) =>
                    this.onCurrencyChange({ currency: event.target.value })
                  }
                  className="btn btn-light my-1"
                  aria-label="Change Currency"
                >
                  <option value="$">USD (United States Dollar)</option>
                  {/* <option value="£">GBP (British Pound Sterling)</option>
                  <option value="¥">JPY (Japanese Yen)</option>
                  <option value="$">CAD (Canadian Dollar)</option>
                  <option value="$">AUD (Australian Dollar)</option>
                  <option value="$">SGD (Signapore Dollar)</option>
                  <option value="¥">CNY (Chinese Renminbi)</option> */}
                  <option value="₹">RUPEE (RP)</option>
                  <option value="AED">Dirham (AED)</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="my-3">
                <Form.Label className="fw-bold">Tax rate:</Form.Label>
                <InputGroup className="my-1 flex-nowrap">
                  <Form.Control
                    name="taxRate"
                    type="number"
                    value={this.state.taxRate}
                    onChange={(event) => this.editField(event)}
                    className="bg-white border"
                    placeholder="0.0"
                    min="0.00"
                    step="0.01"
                    max="100.00"
                  />
                  <InputGroup.Text className="bg-light fw-bold text-secondary small">
                    %
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="my-3">
                <Form.Label className="fw-bold">Discount rate:</Form.Label>
                <InputGroup className="my-1 flex-nowrap">
                  <Form.Control
                    name="discountRate"
                    type="number"
                    value={this.state.discountRate}
                    onChange={(event) => this.editField(event)}
                    className="bg-white border"
                    placeholder="0.0"
                    min="0.00"
                    step="0.01"
                    max="100.00"
                  />
                  <InputGroup.Text className="bg-light fw-bold text-secondary small">
                    %
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="my-3">
                <Form.Label className="fw-bold">GST Number</Form.Label>
                <InputGroup className="my-1 flex-nowrap">
                  <Form.Control
                    name="gstNo"
                    type="text"
                    value={this.state.gstNo}
                    onChange={(event) => this.editField(event)}
                    className="bg-white border"
                    placeholder="#########"
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="my-3">
                <Form.Label className="fw-bold">SEBI Number</Form.Label>
                <InputGroup className="my-1 flex-nowrap">
                  <Form.Control
                    name="sebi"
                    type="text"
                    value={this.state.sebi}
                    onChange={(event) => this.editField(event)}
                    className="bg-white border"
                    placeholder="#########"
                  />
                </InputGroup>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default InvoiceForm;
