import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Container from 'react-bootstrap/Container';
import { Home, InvoiceForm, EditInvoice, Error, SignIn } from './pages';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: '/create-invoice',
    element: <InvoiceForm />,
    errorElement: <Error />,
  },
  {
    path: '/edit-invoice/:id',
    element: <EditInvoice />,
    errorElement: <Error />,
  },
]);


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track authentication status

  useEffect(()=>{
    const token = localStorage.getItem('x-token-access');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  },[])
  const handleSignIn = (data) => {
    // Perform sign-in logic here (e.g., authenticate user)
    const token = localStorage.getItem('x-token-access');
    if (data || token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  return (
    <div className="App d-flex flex-column align-items-center w-100">
      <Container>
        {isLoggedIn ? ( // Conditionally render based on authentication status
          <RouterProvider router={router} />
        ) : (
          <SignIn In onSignIn={handleSignIn} />
        )}
      </Container>
    </div>
  );
};

export default App;
